<template>
    <div class="rol">
        <!-- alerta CRUD -->
        <v-snackbar v-model="alerta" :color="colorAlerta" right top :timeout="timeout">
            {{ textoAlerta }}
            <template v-slot:action="{ attrs }">
                <v-btn text v-bind="attrs" @click="alerta = false">
                    Cerrar
                </v-btn>
            </template>
        </v-snackbar>
        <!-- fin alerta CRUD -->
        <v-card>
            <v-dialog v-model="modalRol" max-width="500px" persistent>
                <v-card>
                    <v-toolbar color="primary" dark>
                        <v-card-title>
                            <span class="text-h5">{{ tituloRol }}</span>
                        </v-card-title>
                    </v-toolbar>
                    <v-card-text>
                        <v-container>
                            <v-form ref="frmRol" v-model="validoRol" lazy-validation>
                                <v-text-field v-model="editRol.nombre" :rules="reglaNombreRol"
                                    prepend-inner-icon="mdi-format-text" label="Name" outlined clearable required>
                                </v-text-field>
                                <v-autocomplete v-model="editRol.permiso" :items="permisos" chips deletable-chips
                                    small-chips multiple item-text="nombre" item-value="Uid" label="Permisos" outlined
                                    return-object>
                                </v-autocomplete>
                                <v-divider></v-divider>
                                <v-card-actions>
                                    <v-spacer></v-spacer>
                                    <v-btn color="error" class="mr-4" @click="cancelarRol">
                                        Cancelar
                                    </v-btn>
                                    <v-btn :disabled="!validoRol" color="success" class="mr-4" @click="guardarRol">
                                        Guardar
                                    </v-btn>
                                </v-card-actions>
                            </v-form>
                        </v-container>
                    </v-card-text>
                </v-card>
            </v-dialog>
            <v-dialog v-model="modalEliminarRol" max-width="550px">
                <v-card>
                    <v-card-title class="text-h6 font-weight-ligth text--secondary">
                        <v-icon x-large left color="error">
                            mdi-alert-octagon-outline
                        </v-icon>
                        ¿Estas seguro que deceas eliminar este campo?
                    </v-card-title>
                    <v-divider></v-divider>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="error" @click="cerrarEliminarRol">
                            Cancelar
                        </v-btn>
                        <v-btn color="success" @click="confirmarElimRol">
                            Aceptar
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
            <!-- tabla -->
            <tabla :header="headersRol" :body="roles" tituloTabla="ROLES" :accionAgregar="true" :exportar="true"
                @recargar="listarRol" @agregar="agregarRol" @actualizar="editarRol" @eliminar="eliminarRol" />
            <!-- tabla fin -->
        </v-card>
    </div>
</template>
<style>
</style>
<script>
    import {
        mapState,
        mapActions
    } from "vuex";
    import tabla from "../../components/view/Tabla";
    export default {
        name: "rol",
        components: {
            tabla,
        },
        data: () => ({
            url_api: process.env.VUE_APP_URL_API,

            alerta: false,
            textoAlerta: '',
            colorAlerta: '',
            timeout: 2000,
            // variables permiso 
            UidPermiso: null,
            permisos: [],

            // fin variables permiso
            // variables rol 
            UidRol: null,
            buscarRol: '',
            validoRol: true,
            modalRol: false,
            modalRolPermiso: false,
            modalEliminarRol: false,
            headersRol: [{
                    text: '#',
                    value: 'numero'
                },
                {
                    text: 'Nombre / Rol',
                    align: 'start',
                    value: 'nombre',
                },

                {
                    text: 'Acciones',
                    value: 'acciones',
                    sortable: false
                },
            ],
            roles: [],
            reglaNombreRol: [
                v => !!v || 'El nombre del rol es obligatorio.'
            ],
            editedRolIndex: -1,
            // permiso: null,

            editRol: {
                nombre: '',
                permiso: null
            },
            defaultRol: {
                nombre: '',
                permiso: null
            },
            // fin variables rol
        }),

        computed: {
            ...mapState(["token"]),
            // titulo modal rol 
            tituloRol() {
                return this.editedRolIndex === -1 ? 'Nuevo Rol' : 'Editar Rol'
            },
        },

        watch: {
            // modal rol
            modalRol(val) {
                val || this.cancelarRol()
            },

            // fin modal rol

        },

        created() {
            // listar roles
            this.listarRol()
            // listar permisos
            this.listarPermiso()
        },

        methods: {
            ...mapActions(['cerrarSesion']),

            // CRUD roles 
            async listarRol() {
                try {
                    await fetch(`${this.url_api}/rol`, {
                            headers: {
                                'Content-Type': 'application/json',
                                "auth-token": this.token
                            }
                        }).then(res => res.json())
                        .catch(error => console.error('Error:', error))
                        .then(response => {
                            if (response.body) {
                                this.roles = response.body
                            } else if (response.error == ('!token' || 'tokenNull')) {
                                this.cerrarSesion();
                            }
                        });
                } catch (error) {
                    console.error('Error:', error);
                }
            },

            agregarRol() {
                this.modalRol = true;
            },

            editarRol(rol) {
                this.UidRol = rol.Uid;
                this.editedRolIndex = this.roles.indexOf(rol)
                this.editRol = Object.assign({}, rol)
                this.modalRol = true;
                var perm = [];
                this.permisos.forEach(elemen => {
                    rol.permiso.forEach(element => {
                        if (elemen.Uid == element.Uid) {
                            perm.push({
                                Uid: element.Uid,
                                nombre: element.nombre
                            });
                        }
                    });
                });
                this.editRol.permiso = perm;
            },

            eliminarRol(rol) {
                this.editedRolIndex = this.roles.indexOf(rol)
                this.editRol = Object.assign({}, rol)
                this.modalEliminarRol = true
                this.UidRol = rol.Uid;
            },

            async confirmarElimRol() {
                try {
                    await fetch(`${this.url_api}/rol/${this.UidRol}`, {
                            method: 'DELETE',
                            headers: {
                                'Content-Type': 'application/json',
                                "auth-token": this.token
                            }
                        }).then(res => res.json())
                        .catch(error => console.error('Error:', error))
                        .then(response => {
                            if (response.body == 'exito') {
                                this.alerta = false;
                                this.textoAlerta = 'Dato eliminado con Exito!';
                                this.colorAlerta = 'warning'
                                this.alerta = true;
                                this.roles.splice(this.editedRolIndex, 1);
                            } else if (response.error == ('!token' || 'tokenNull')) {
                                this.cerrarSesion();
                            } else if (response.error) {
                                this.alerta = false;
                                this.textoAlerta = 'Error al eliminar!';
                                this.colorAlerta = 'error'
                                this.alerta = true;
                            }
                        });
                } catch (error) {
                    console.error('Error:', error);
                }
                this.cerrarEliminarRol();
            },

            cancelarRol() {
                this.$refs.frmRol.reset()
                this.$refs.frmRol.resetValidation()
                this.modalRol = false
                this.$nextTick(() => {
                    this.editRol = Object.assign({}, this.defaultRol)
                    this.editedRolIndex = -1
                })
            },

            cerrarEliminarRol() {
                this.modalEliminarRol = false
                this.$nextTick(() => {
                    this.editRol = Object.assign({}, this.defaultRol)
                    this.editedRolIndex = -1
                })
            },

            async guardarRol() {
                if (this.$refs.frmRol.validate()) {
                    if (this.editedRolIndex > -1) {
                        try {
                            await fetch(`${this.url_api}/rol/${this.UidRol}`, {
                                    method: 'POST',
                                    headers: {
                                        'Content-Type': 'application/json',
                                        "auth-token": this.token
                                    },
                                    body: JSON.stringify(this.editRol)
                                }).then(res => res.json())
                                .catch(error => console.error('Error:', error))
                                .then(response => {
                                    if (response.body == 'exito') {
                                        this.alerta = false;
                                        this.textoAlerta = 'Dato actualizado con Exito!';
                                        this.colorAlerta = 'primary'
                                        this.alerta = true;
                                        Object.assign(this.roles[this.editedRolIndex], this.editRol)
                                    } else if (response.error == ('!token' || 'tokenNull')) {
                                        this.cerrarSesion();
                                    } else if (response.error) {
                                        this.alerta = false;
                                        this.textoAlerta = 'Error al actualizar!';
                                        this.colorAlerta = 'error'
                                        this.alerta = true;
                                    }
                                });
                        } catch (error) {
                            console.error('Error:', error);
                        }
                        this.UidRol = null;
                    } else {
                        try {
                            await fetch(`${this.url_api}/rol`, {
                                    method: 'POST',
                                    headers: {
                                        'Content-Type': 'application/json',
                                        "auth-token": this.token
                                    },
                                    body: JSON.stringify(this.editRol)
                                }).then(res => res.json())
                                .catch(error => console.error('Error:', error))
                                .then(response => {
                                    if (response.body == 'exito') {
                                        this.alerta = false;
                                        this.textoAlerta = 'Dato agregado con Exito!';
                                        this.colorAlerta = 'success'
                                        this.alerta = true;
                                        this.listarRol();
                                    } else if (response.error == ('!token' || 'tokenNull')) {
                                        this.cerrarSesion();
                                    } else if (response.error) {
                                        this.alerta = false;
                                        this.textoAlerta = 'Error al agregar!';
                                        this.colorAlerta = 'error'
                                        this.alerta = true;
                                    }
                                });
                        } catch (error) {
                            console.error('Error:', error);
                        }
                    }
                    this.cancelarRol()
                }
            },
            // fin CRUD roles
            // CRUD permisos 
            async listarPermiso() {
                try {
                    await fetch(`${this.url_api}/permiso`, {
                            headers: {
                                'Content-Type': 'application/json',
                                "auth-token": this.token
                            }
                        }).then(res => res.json())
                        .catch(error => console.error('Error:', error))
                        .then(response => {
                            if (response.body) {
                                this.permisos = response.body;
                            } else if (response.error == ('!token' || 'tokenNull')) {
                                this.cerrarSesion();
                            }
                        });
                } catch (error) {
                    console.error('Error:', error);
                }
            },

            // fin CRUD permisos
        },
    }
</script>